import { React, useState, useEffect} from "react"
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'

const Switcher = () => {
    function useDarkSide() {
        const [theme, setTheme] = useState(localStorage.theme);
        const colorTheme = theme === "dark" ? "light" : "dark";
        localStorage.setItem("theme", theme);
        useEffect(() => {
            const root = window.document.documentElement;
            root.classList.remove(colorTheme);
            root.classList.add(theme);
            if (localStorage.theme === "dark")
                localStorage.removeItem("theme");
            else localStorage.setItem("theme", theme);
        }, [theme, colorTheme]);
    
        return [colorTheme, setTheme];
    }
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(
        colorTheme === "light" ? true : false
    );

    const toggleDarkMode = (checked) => {
        console.log(checked)
        let theme = checked ? "dark": "light"
        setTheme(theme);
        setDarkSide(checked);
    };
    return (
        <>  
            {
                !darkSide ? 
                <MoonIcon className="h-6 w-6 text-liver" onClick={()=> toggleDarkMode(true)}/> :
                <SunIcon className="h-6 w-6 text-liver dark:text-darkin-text" onClick={()=> toggleDarkMode(false)}/>
            }
        </>
    )
}

export default Switcher