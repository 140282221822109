import { React, useEffect, useState} from "react";
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import bannerImg from '../assets/Images/profile-removebg.png'
//import vueJsIcon from '../assets/Images/vue-js.svg'
import htmlIcon from '../assets/Images/html.svg'
import jsIcon from '../assets/Images/js.svg'
import typescriptIcon from '../assets/Images/typescript.svg'
import cssIcon from '../assets/Images/css.svg'
import phpIcon from '../assets/Images/php.svg'
import nodeIcon from '../assets/Images/nodejs.svg'
import pythonIcon from '../assets/Images/python.svg'
import nuxtIcon from '../assets/Images/nuxt-js.svg'
import dockerIcon from '../assets/Images/docker.svg'
import kubernetesIcon from '../assets/Images/kubernetes.svg'
import figmaIcon from '../assets/Images/figma.svg'
import awsIcon from '../assets/Images/amazon-web-services.svg'
//import caseImg from '../assets/Images/case-banner.png'
//import codeIcon from '../assets/Images/code.png'
import BlinkWord from "../components/Utils/blinkWord";
import Cases from "../components/Utils/cases";
import aboutImg from '../assets/Images/about.jpg';
// eslint-disable-next-line
import { animated, useSpring } from "@react-spring/web";
//import bgImg from '../assets/Images/bg-banner.svg'
//import { ArrowUpRightIcon, GlobeAltIcon, MapPinIcon, CalendarIcon } from '@heroicons/react/24/solid'
import { Github, Linkedin, Twitter, } from 'lucide-react'
import { collection, getDocs } from "firebase/firestore";
import firebase from '../firebase/index.js'
const Home = () => {

    //let casesData = []
    //const [projectCases, setProjectCases] = useState([])
    const [casesData, setCasesData] = useState([])
    //console.log(firebase)
    let caseIndex = 0
    // eslint-disable-next-line
    const [slideTrans, setSlideTrans] = useState(0)

    useEffect(()=> {
        console.log("using effect")
        const queryCases = async () => {
            
            try {
                const projects =  await getDocs(collection(firebase.db, "projects_cases"))
                const newCases = []
                projects.forEach((doc)=> {
                    console.log(doc)
                    newCases.push(doc.data())
                })
                console.log(newCases)
                setCasesData(newCases)
                console.log(casesData)
                
            } catch (error) {
                
            }

        };
        //console.log(queryCases)
        //(async () => await queryCases())()
        queryCases()
        //return ()=> console.log("cleaned up")
    },
    //eslint-disable-next-line 
    []
    
    )
    
    /*
    setInterval(()=> {
        console.log(slideTrans)
    // eslint-disable-next-line
        const slideValue = slideTrans + 1
        //setSlideTrans(slideValue)
    }, 100)
    */
    
    // eslint-disable-next-line
    const [aboutSpring, aboutApi] = useSpring(()=> ({
        from: {x: 0}
    }))

    const handleAboutAnim = () => {
        aboutApi.start({
            from: {x:0 , y:0},
            to:{x: -300}
        })
    }

    // Skills array

    const skills = [
        {
            icon: htmlIcon,
            name: 'HTML'
        },
        {
            icon: cssIcon,
            name: 'CSS'
        },
        {
            icon: jsIcon,
            name: 'Javascript'
        },
        {
            icon: typescriptIcon,
            name: 'Typescript'
        },
        {
            icon: nuxtIcon,
            name: 'NuxtJs'
        },
        {
            icon: figmaIcon,
            name: 'Figma'
        },
        {
            icon: nodeIcon,
            name: 'Node'
        },
        {
            icon: phpIcon,
            name: 'PHP'
        },
        {
            icon: pythonIcon,
            name: 'Python'
        },
        {
            icon: dockerIcon,
            name: 'Docker'
        },
        {
            icon: kubernetesIcon,
            name: 'Kubernetes'
        },
        {
            icon: awsIcon,
            name: 'AWS'
        },
    ]

    return (
        <div className="home-page dark:home-page--dark">
            <main>
                <section className="home-section">
                    <Navbar/>
                    <div className="banner-hero default-ctn" id="home">
                        <div className="hero-left">
                            <h2 className="hero-name greeting">Hello 👋</h2>
                            {/* <h2 className="hero-name">I'm Harold Adjahi</h2> */}
                            <h4 className="hero-title">I'm a <BlinkWord/>  developer</h4>
                            <p> 
                                I'm Harold Adjahi, a <strong>Software Engineer</strong> | <strong>ALX Graduated</strong> building end to end web applications and websites and solving engineering problems. 
                            </p>
                            <div className="profile-links">
                                <a href="https://github.com/HaroldHang" target="_blank" rel="noreferrer" className="link">
                                    <Github/>
                                </a>
                                <a href="https://x.com/harold_adjahi" target="_blank" rel="noreferrer" className="link">
                                    <Twitter/>
                                </a>
                                <a href="https://www.linkedin.com/in/harold-adjahi-a88a46122" target="_blank" rel="noreferrer" className="link">
                                    <Linkedin/>
                                </a>
                            </div>
                        </div>
                        <div className="hero-right">
                            <div className="hero-image">
                                <img src={bannerImg} alt="nothing"/>
                            </div>  
                        </div>
                    </div>
                    <div className="about-me default-ctn" id="about">
                        <h1 className="section-title"> About Me </h1>
                        <div className="about-inner">
                            <div className="about-image" onMouseEnter={handleAboutAnim}>
                                <img src={aboutImg} alt="nothing"/>
                                {/* <animated.div className="spring-about" style={{...aboutSpring}}/> */}
                            </div>
                            <div className="about-desc">
                                <h2 className="name">Harold Adjahi</h2>
                                <p className="occup-title">FullStack Software Engineer and Web Designer</p>
                                <p className="about-text">
                                    Originally I'm a graduated student in biomedical engineering and I wanted to be in development industry. Then in my second year of my studies I decided to go for a self-taught programming by looking for online programs, bootcamp and courses. Thanks for my resilience and my motivation, I was able to teach myself all the essential things I need to be a software developer.
                                    
                                </p>
                                <a href="/" className="btn-primary dark:btn-primary--dark">
                                    Contact Me
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="services default-ctn">
                        <div className="service-wrapper">
                            <h1 className="section-title section-title--left">Services</h1>
                            <div className="services-ctn">
                                <div>
                                    <h4>FullStack Development</h4>
                                    <div className="service-illustration">
                                        Illustrate
                                    </div>
                                    <div className="service-description">
                                        My service description
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="skills default-ctn" id="skills">
                        <h1 className="section-title"> My Skills </h1>
                        <div className="skill-wrapper">
                            <div className="skills-ctn">
                                <div className="skills-track" style={{transform: "translateX(-" + slideTrans + "px)"}}>
                                    {
                                        skills.map((skill) => {
                                            return (
                                                <div className="skill-instance" key={skill.name}>
                                                    <div className="skill-icon">
                                                        <img src={skill.icon} alt="nothing"/>
                                                    </div>
                                                    <div className="skill-progress">
                                                        <p>{skill.name}</p>
                                                        
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cases default-ctn" id="cases">
                        <div className="case-wrapper">
                            <h1 className="section-title">My Cases</h1>
                            <div className="cases-ctn">
                                
                                {
                                    casesData.map(project => {
                                        caseIndex++
                                        return <Cases data={project} key={project.url + `-${caseIndex}`} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className="experiences default-ctn">
                        <div className="exp-wrapper">
                            <h1 className="section-title">My Experiences</h1>
                            <div className="exp-ctn">
                                <div className="exp-instance">
                                    <div className="exp-icon">
                                        <img src={codeIcon} alt="code"/>
                                    </div>
                                    <div className="exp-desc">
                                        <div className="exp-head">
                                            <h1>Full stack Developer</h1>
                                        </div>
                                        <div className="exp-head">
                                            <MapPinIcon className="icon-size-sm"/>
                                            <p>
                                                Part time
                                            </p>
                                            <span className="separator"></span>
                                            <h2>Takafa Mindset</h2>
                                        </div>
                                        <div className="exp-head">
                                            <CalendarIcon className="icon-size-sm"/>
                                            <p>
                                                Jul 2021 - Present
                                            </p>
                                        </div>
                                        <div className="exp-head">
                                            <GlobeAltIcon className="icon-size-sm"/> <a href="/" className="btn-link btn-link--reset" target="_blank">takafamindset.com</a>
                                        </div>
                                        <ul>
                                            <li><span className="separator"></span> Manages projects for the company</li>
                                            <li> <span className="separator"></span> Build frontend and backends system</li>
                                            <li><span className="separator"></span> Web app Deployment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/*<div className="testimonials default-ctn">
                        <div className="testi-wrapper">
                        <h1 className="section-title">My clients say</h1>
                            <div className="testi-ctn">
                                <div className="testi-instance">
                                    <div className="testi-profile">
                                        <div className="testi-img">
                                            <img src={bannerImg} alt="testi"/>
                                        </div>
                                        <div className="testi-name">
                                            <h1>Full stack Developer</h1>
                                        </div>
                                    </div>
                                    <div className="testi-desc">
                                        <p>
                                            Lorem ipsum Manages projects for the company Build frontend and backends system Web app Deployment Web app Deployment Web app Deployment Web app Deployment Web app Deployment Web app Deployment Web app Deployment
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="contact default-ctn" id="contact">
                        <div className="contact-wrapper">
                            <h1 className="section-title">Contact Me</h1>
                            <div className="contact-ctn">
                                <form className="contact-form">
                                    <h3>Get in touch</h3>
                                    <div className="form-instance">
                                        <label>Name</label>
                                        <input type="text" placeholder="Your Name"/>
                                    </div>
                                    <div className="form-instance">
                                        <label>Email</label>
                                        <input type="email" placeholder="Your Email"/>
                                    </div>
                                    <div className="form-instance">
                                        <label>Subject</label>
                                        <textarea placeholder="Your Project"/>
                                    </div>
                                    <div className="form-instance">
                                        <button className="btn-primary dark:btn-primary--dark">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </section>
                
            </main>
        </div>
    )
};

export default Home