import { React, useRef, useState, useEffect} from "react"
//import { BsFillMoonStarsFill } from "react-icons"
// eslint-disable-next-line
import { Link } from "react-router-dom";
import Switcher from "./Switcher";
//import { Nav, NavLink, NavMenu } from "./NavbarElements";
import firebase from '../../firebase/index.js'

import { ref, getDownloadURL } from "firebase/storage"

const Navbar = () => {
    const storage  = firebase.storage
    const [resumePath, setResumePath] = useState('')
    const [state, setState] = useState(false)
    const navRef = useRef()

    // Replace # path with your path
    const navigation = [
        { 
            title: "Home", 
            path: "#home" 
        },
        { 
            title: "About", 
            path: "#about" 
        },
        { 
            title: "Skills", 
            path: "#skills" 
        },
        { 
            title: "Cases", 
            path: "#cases" 
        },
        /*{
            title : "Services",
            path : "/"
        },*/
        { 
            title: "Contact", 
            path: "#contact" 
        },
        //{ title: "Partners", path: "#" },
        //{ title: "Blog", path: "#" }
    ]

    const myResume = ref(storage, `public/Harold_Adjahi Resume.pdf`)


    useEffect(() => {
        const body = document.body
        // Disable scrolling
        const customBodyStyle = ["overflow-hidden", "lg:overflow-visible"]
        if (state) body.classList.add(...customBodyStyle)
        // Enable scrolling
        else body.classList.remove(...customBodyStyle)

        // Sticky strick
        const customStyle = ["sticky-nav", "fixed", "border-b"]
        window.onscroll = () => {
            if (window.scrollY > 80) navRef.current.classList.add(...customStyle)
            else navRef.current.classList.remove(...customStyle)
        }

        // Resume path
        const getUrl = async () => {
            const url = await getDownloadURL(myResume).then(url => { return url})
            console.log(url)
            setResumePath(url)
        }
        (async () => await getUrl() )()
    }, [state, myResume])
    

    return (
        <nav ref={navRef} className="nav-ctn dark:nav-ctn--dark dark:border-none">
            <div className="nav-wrapper">
                <div className="">
                    <a href="/" className="text-xl">
                        HaroldHang
                    </a>
                    <div className="lg:hidden">
                        <button className="text-gray-700 dark:text-darkin-text outline-none p-2 rounded-md focus:border-gray-400 focus:border" onClick={() => setState(!state)}>
                            {
                                state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                    </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
                <div className={`${ state ? 'h-screen pb-20 overflow-auto flex' : 'hidden lg:flex'}`}>
                    <div className="right-side">
                        <ul className="">
                            <li className="status">
                                <div className="spin-ctn">
                                    <span className="animate-ping spinner">
                                    </span> 
                                    <span className="inner-spin"></span>
                                </div>
                                <span className="av">Available</span>
                            </li>
                            <li className="">
                                <span className="cursor-pointer switcher">
                                    <Switcher/>
                                </span>
                            </li>
                            <li className="mt-8 hidden lg:block lg:mt-0">
                                <a href={resumePath} target="_blank" rel="noreferrer" className="btn-primary dark:btn-primary--dark  lg:inline">
                                    Resume
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="nav-side">
                        <ul className="">
                            {
                                navigation.map((item, idx) => {
                                    return (
                                        <li key={idx} className="nav-text dark:nav-text--dark">
                                            <a href={item.path}>
                                                { item.title }
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;