import { useState, useEffect } from "react"

const BlinkWord = () => {

    //const [specInd, setSpecInd] = useState(0)
    //const [specPos, setSpecPos] = useState(0)
    //const [isWrite, setIsWrite] = useState(false)
    //const [isWordFinish, setIsWordFinish] = useState(false)
    const [currentSpec, setCurrentSpec ] = useState('')
    //const [intervalSec, setIntervalSec] = useState(250) 
    useEffect(()=> {
        const specialities = ['frontend', 'backend', 'full-stack']
        /*
        const writeWord = (wordToWrite) => {
            
            if (isWordFinish) return
            if (currentSpec === wordToWrite) {
                setSpecPos(-1)
                setIsWordFinish(true)
                
            } else {
                
                setSpecPos(specPos => specPos + 1)
                setCurrentSpec(currentSpec => currentSpec.concat(wordToWrite[specPos]))
                //return false
                //console.log('Out indPos:',specPos)
            }
        }
        // Backspacing the word
        const unWriteWord = (wordToUnwrite) => {
//            console.log("unwriting", currentSpec, specPos)
            if (currentSpec === '') {
                //console.log("unwrite finish")
                setSpecPos(0)
                setIsWrite(true)
                setIsWordFinish(false)
            } else {
                setCurrentSpec(currentSpec => currentSpec.slice(0, specPos))
                setSpecPos(specPos => specPos - 1 )
                //console.log('Out indPos:',specPos)
            }
        }
        const blinkSpec = setInterval(()=> {
            
            if (specInd === specialities.length) {
                clearInterval(blinkSpec)
                setSpecInd(0)
                setSpecPos(0)
                //return
            }
            else {
                
                if (isWrite) {
                    setSpecInd(specInd => specInd + 1)
                    setIsWrite(false)
                    setIntervalSec(200)
                } else if (isWordFinish) {
                    setIntervalSec(100)
                    unWriteWord(specialities[specInd])
                } else writeWord(specialities[specInd])
            }
        }, intervalSec)
        */
        //return () => clearInterval(blinkSpec)
        const typeSpeed = 250; // Speed for typing
        const eraseSpeed = 100; // Speed for erasing
        const delayBetweenWords = 1500; // Delay after a word is completed
        let currentIndex = 0
        let currentWord = specialities[currentIndex];
        let charIndex = -1;
        let isDeleting = false;
        let typingDelay = typeSpeed;

        const typeWriter = () => {
            //console.log(currentWord.charAt(charIndex))
            if (!isDeleting && charIndex < (currentWord.length-1)) {
                // Typing logic
                //setCurrentSpec((prev) => prev + currentWord.charAt(charIndex));
                charIndex += 1;
                setCurrentSpec((prev) => prev.concat(currentWord[charIndex]));
                typingDelay = typeSpeed;
            } else if (isDeleting && charIndex >= 0) {
                // Deleting logic
                setCurrentSpec((prev) => prev.slice(0, -1));
                charIndex -= 1;
                typingDelay = eraseSpeed;
            } else if (!isDeleting && charIndex === (currentWord.length-1)) {
                // Word completed
                typingDelay = delayBetweenWords;
                isDeleting = true;
            } else if (isDeleting && charIndex <= 0) {
                // Move to the next word
                isDeleting = false;
                //setSpecInd((prev) => (prev + 1) % specialities.length);
                //setSpecInd(specInd => specInd + 1)
                if (currentIndex === (specialities.length - 1)) {
                    currentIndex = 0
                } else {
                    currentIndex++
                }
                //currentWord = specialities[(specInd + 1) % specialities.length];
                currentWord = specialities[currentIndex];
            }
            setTimeout(typeWriter, typingDelay);
        };

        const typingTimer = setTimeout(typeWriter, typingDelay);

        // Cleanup function
        return () => clearTimeout(typingTimer);

    }, 
    //[currentSpec, intervalSec, isWordFinish, isWrite, specInd, specPos]
    // eslint-disable-next-line
    []
)

    return (
        <>
            <span>{ currentSpec }</span><span className="blink-cursor"></span>
        </>
    )
}

export default BlinkWord