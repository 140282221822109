import  { useState, useEffect } from "react"
import { ArrowUpRightIcon } from '@heroicons/react/24/solid'
import { Github } from 'lucide-react'
import firebase from '../../firebase/index.js'
import { ref, getDownloadURL } from "firebase/storage"

const Cases = ({data}) => {
    const [imgPath, setImgPath] = useState('')
    const storage  = firebase.storage
    //const imgBaseUrl = firebase.storageUrl
    const imgUrl = ref(storage, `casesImages/${data.imgPath}`)
    console.log(imgUrl.fullPath)
    useEffect(()=> {
        const getUrl = async () => {
            const url = await getDownloadURL(imgUrl).then(url => { return url})
            console.log(url)
            setImgPath(url)
        }
        (async () => await getUrl() )()
    }, [imgUrl])
    
    let index = 0
    return (
        <>
            <div className="case-instance">
                <div className="case-img">
                    <img src={imgPath} alt="Banner"/>
                </div>
                <div className="case-desc">
                    <h2>{data.name}</h2>
                    <p>
                        {data.description} 
                    </p>
                    <div className="stack">
                        {
                            data.stack.map(tech => {
                                index++
                                return <span className="spot-tag dark:spot-tag--dark" key={tech + `-${index}`}>{tech}</span>
                            })
                        }
                    </div>
                </div>
                <div className="case-link">
                    <a href={data.link} className="btn-link dark:btn-link--dark" target="_blank" rel="noreferrer">
                        Live Link <ArrowUpRightIcon className="icon-size-sm"/>
                    </a>
                    <a href={data.github} className="btn-primary dark:btn-primary--dark" target="_blank" rel="noreferrer">
                        Source Code <Github/>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Cases