import { Mail } from 'lucide-react'

const Footer  =()=> {


    return (
        <>
            <footer className="footer w-full py-6 dark:bg-liver-darker">
                <div className="footer-wrapper px-4 w-full lg:w-1/2 mx-auto flex justify-center lg:justify-between items-center text-sm">
                    <div className="flex gap-2 items-center">
                        <a href="/" className="">
                            HaroldHang
                        </a> 
                        <span className=''>&copy;Copyright 2023-{(new Date()).getFullYear()}</span>
                    </div>
                    <div className='flex items-center justify-center'>
                        <a href='mailto:han20tuf@gmail.com' className='flex gap-1 items-center'><Mail className='w-4'/> Email-me</a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer