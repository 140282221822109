import React from "react";

const Contact = () => {
    return (
        <div>
            <h3>Mail me at han20tuf@gmail.com</h3>
        </div>
    )
};

export default Contact